import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import styled, { css } from 'styled-components';
import Image from 'next/image';

interface IAlert extends AlertProps {
  children: AlertProps['children'];
  hideCloseButton?: boolean;
  background?: boolean;
}

const CustomMuiAlert = styled(MuiAlert)`
  &.MuiAlert-filledSuccess {
    background-color: ${props => props.theme.paletteDefault.accent};
  }

  &.MuiAlert-root {
    ${(props: IAlert) =>
      !props.background
        ? css`
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
          `
        : null};
  }
`;

const CloseIconWrapper = styled.div`
  padding: 4px;
  display: flex;
  cursor: pointer;
`;

const Alert: React.FC<IAlert> = ({
  children,
  hideCloseButton = false,
  background = true,
  ...props
}) => {
  return (
    <CustomMuiAlert
      elevation={background ? 6 : 0}
      background={background}
      {...props}
      action={
        !hideCloseButton ? (
          <CloseIconWrapper onClick={props.onClose}>
            <Image
              src="/asset/closeIcon.svg"
              alt={'close icon'}
              width={16}
              height={16}
              unoptimized={true}
            />
          </CloseIconWrapper>
        ) : null
      }>
      {children}
    </CustomMuiAlert>
  );
};

export default Alert;
