import React from 'react';
import MuiBackdrop, { BackdropProps } from '@material-ui/core/Backdrop';

export interface IBackdrop extends BackdropProps {}

const Backdrop: React.FC<IBackdrop> = ({ children, ...props }) => {
  return <MuiBackdrop {...props}>{children}</MuiBackdrop>;
};

export default Backdrop;
