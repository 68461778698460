import { FC } from 'react';
import { SnackBar as SnackBarAtom, Alert } from '@/components/atoms';
import { ISnackBarMoleculeCmp } from './ISnackBarMoleculeCmp';
import styled, { useTheme } from 'styled-components';
import { SnackBar } from '@/store/reducers/ui';

type StyleProps = {
  $color: string;
};
const CustomAlert = styled(Alert)<StyleProps>`
  &.MuiAlert-root {
    background-color: ${props => props.theme.snackbar.backgroundColor};
    border-left: 4px solid ${props => props.$color};
    color: ${props => props.$color};
  }
  & .MuiAlert-icon {
    color: ${props => props.$color};
  }
`;
const Snackbar: FC<ISnackBarMoleculeCmp> = ({
  open,
  closeHandler,
  position,
  type,
  message,
}) => {
  const theme = useTheme();
  const getAlertColor = (type: SnackBar['type']) => {
    switch (type) {
      case 'success':
        return theme.paletteDefault.green;
      case 'error':
        return theme.paletteDefault.error.main;
      case 'warning':
        return theme.paletteDefault.orange;
      default:
        return theme.paletteDefault.violet;
    }
  };

  const snackbarInfo = {
    content: (
      <CustomAlert
        $color={getAlertColor(type)}
        severity={type!}
        onClose={e => {
          if (closeHandler) {
            closeHandler(e, 'timeout');
          }
        }}>
        <>{message}</>
      </CustomAlert>
    ),
    duration: 4000,
  };

  return (
    <SnackBarAtom
      anchorOrigin={position}
      open={open}
      autoHideDuration={snackbarInfo.duration}
      onClose={closeHandler}>
      {snackbarInfo.content}
    </SnackBarAtom>
  );
};

export default Snackbar;
