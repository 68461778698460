import React from 'react';
import MUISnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';

interface ISnackBarAtomCmp extends SnackbarProps {
  children: React.ReactElement | undefined;
}

const Snackbar: React.FC<ISnackBarAtomCmp> = ({ children, ...props }) => {
  return props?.open ? (
    <MUISnackbar
      {...props}
      anchorOrigin={
        props.anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }>
      {children}
    </MUISnackbar>
  ) : null;
};

export default Snackbar;
