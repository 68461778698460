import MuiTextField, {
  OutlinedTextFieldProps,
} from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';
import useIsMobile from '@/hooks/useIsMobile';

interface ITextField extends OutlinedTextFieldProps {}

const CustomTextField = styled(MuiTextField)`
  & label.Mui-focused {
    color: ${props => props.theme.typog.color};
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      color: ${props => props.theme.typog.color};
    }
    &.Mui-focused fieldset {
      border-color: ${props => props.theme.typog.color};
    }
  }

  .MuiInputBase-input {
    font-family: ${props => props.theme.typog.fontFamily};
    color: ${props => props.theme.typog.color};
  }

  .MuiFormLabel-root {
    font-size: 12px;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    .MuiFormLabel-root {
      font-size: 14px;
    }
  }
`;

const TextField = React.forwardRef<HTMLInputElement, ITextField>(
  ({ children, ...props }, ref) => {
    const isMobile = useIsMobile();

    return (
      <CustomTextField
        size={isMobile ? 'small' : 'medium'}
        {...props}
        inputRef={ref}
        InputProps={{
          classes: {
            root: 'root',
          },
        }}
      />
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
