import React, { SyntheticEvent } from 'react';
import { RootState } from '@/store/store';
import { useSelector, useDispatch } from 'react-redux';
import { closeSnackbar } from '@/store/actions/ui';
import { SnackBar as SnackBarMolecule } from '@/components/molecules';

const SnackBar: React.FC = () => {
  const snackBar = useSelector((state: RootState) => state.uiReducer.snackBar);
  const dispatch = useDispatch();

  const closeHandler = (
    _event: SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason != 'clickaway') {
      dispatch(closeSnackbar());
    }
  };

  return snackBar ? (
    <SnackBarMolecule
      open={snackBar?.open || false}
      closeHandler={closeHandler}
      type={snackBar?.type}
      position={snackBar?.position}
      message={snackBar?.message}
    />
  ) : null;
};

export default SnackBar;
